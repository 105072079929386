import { sharedPreferences } from "../styles/theme"

// breakpoints.lg needs to be cleaned and parsed since it is a string like '1200px'
export const detectMobileAndTablet = windowWidth =>
  windowWidth <
  parseInt(sharedPreferences.breakpoints.lg.match(/\d+/gi).join(""))

// detect server-side-rendering to fix bugs while gatsby build
export const isSSR = typeof window === "undefined"

// used to parse the publication date of medium articles
export const parseDate = date => {
  const day = date.substring(5, 7)
  const month = date.substring(8, 11)
  const year = date.substring(12, 16)

  switch (month) {
    case "Jan":
      return "January " + day + ", " + year
    case "Feb":
      return "February " + day + ", " + year
    case "Mar":
      return "March " + day + ", " + year
    case "Apr":
      return "April " + day + ", " + year
    case "May":
      return "May " + day + ", " + year
    case "Jun":
      return "June " + day + ", " + year
    case "Jul":
      return "July " + day + ", " + year
    case "Aug":
      return "August " + day + ", " + year
    case "Sep":
      return "September " + day + ", " + year
    case "Oct":
      return "October " + day + ", " + year
    case "Nov":
      return "November " + day + ", " + year
    case "Dec":
      return "December " + day + ", " + year
    default:
      return "No publication date"
  }
}
